import { http } from '@/core/api/';

const URL = 'api/parent/referralevaluation';

export const getInitFilters = (studentSieId) =>
  http.get(`${URL}/topics/${studentSieId}`);

export const getDisciplineByEvaluation = (studentSieId, evaluationTypeId) =>
  http.get(`${URL}/disciplinetypes/${studentSieId}/${evaluationTypeId}`);

export const getAppointmentInfo = (params, pageIndex, pageSize) =>
  http.post(`${URL}/appointmentinfo/header/${pageIndex}/${pageSize}`, params);

export const getAppointmentDetailInfo = (clientAppointmentId) =>
  http.get(`${URL}/appointmentinfo/detail/${clientAppointmentId}`);
