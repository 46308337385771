<template>
  <custom-callout
    class-main="rounded"
    :controlled="true"
    :opened="data.isOpen"
    @click:open="$emit('on-open-close', data.isOpen)"
  >
    <div class="row py-2 mx-0 align-items-center">
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="font-16 font-weight-bold d-block">
          {{ data.disciplineTypeDescription }}
        </span>
        {{ data.evaluationTypeDescription }}
      </div>
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="data-title d-block">Fecha:</span>
        {{ data.createdDate | formatDate }}
      </div>
      <div class="col-12 col-md-3 mb-3 mb-md-0">
        <span class="data-title d-block">Solicitado por:</span>
        {{ data.appointmentStatusUserName | empty }}
      </div>
      <div class="col-12 col-md-3">
        <span :class="`badge-status-${status.color}`">
          {{ status.name }}
        </span>
      </div>
    </div>
    <template #edition>
      <div class="bg-gray p-2 mt-1">
        <div class="bg-white rounded p-3">
          <appointment-info :client-appointment-id="data.clientAppointmentId" />
        </div>
      </div>
    </template>
  </custom-callout>
</template>

<script>
import CustomCallout from 'custom-callout';
import appointmentStatus from '@/utils/constants/student/appointmentStatusEnum';
import statusColors from '@/utils/constants/statusColors';
import AppointmentInfo from '@/views/students/referral-evaluations/components/AppointmentInfo.vue';

export default {
  name: 'ResultRow',
  components: { CustomCallout, AppointmentInfo },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    status() {
      switch (this.data.appointmentStatusCd) {
        case appointmentStatus.coordinated:
          return { name: 'Cita Coordinada', color: statusColors.Yellow };
        case appointmentStatus.cancelled_De:
        case appointmentStatus.cancelled_Prov:
          return { name: 'Cancelada', color: statusColors.Brown };
        case appointmentStatus.extended:
          return { name: 'En Proceso', color: statusColors.Rose };
        case appointmentStatus.denied:
        case appointmentStatus.completed:
        case appointmentStatus.confirmed:
          return { name: 'Evaluada', color: statusColors.GreenLight };
        default:
          return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
span[class^='badge-status-'] {
  padding: 0.5rem 0;
  min-width: 90%;
}
</style>
