<template>
  <div class="appointment-info">
    <icon-view
      :has-border="false"
      icon="fas fa-info-circle color-orange"
      icon-size="1rem"
      title="Información de Cita"
      variant="custom-icon-view"
    />
    <div class="row mx-2">
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">N&uacute;mero de Cita:</span>
        {{ info.clientAppointmentId }}
      </div>
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Fecha y Hora:</span>
        {{ info.timeStart | formatDate }}
        <em class="color-gray font-14">
          {{ formatedDate }}
        </em>
      </div>
      <div class="w-100 d-none d-md-block" />
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Lugar:</span>
        {{ info.location | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Especialista:</span>
        {{ info.specialistName | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Corporaci&oacute;n:</span>
        {{ info.companyName }}
      </div>
      <div class="w-100 d-none d-md-block" />
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Nombre de la Persona Contacto:</span>
        {{ info.contactName | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Tel&eacute;fono de Proveedor:</span>
        {{ info.locationTelephone | phone }}
      </div>
      <div class="col-12 col-md-3 mb-3">
        <span class="data-title">Direcci&oacute;n F&iacute;sica:</span>
        {{ computedAddress | capitalize }}
      </div>
      <div class="col-12 col-md-3 mb-3 d-flex align-items-center">
        <button
          class="btn custom-map-btn btn-outline-success btn-lg color-orange font-14"
          @click="openMaps"
        >
          <span class="dot">
            <i class="fas fa-map-marker-alt" />
          </span>
          Abrir Mapa
        </button>
      </div>
    </div>
    <icon-view
      :has-border="false"
      icon="fas fa-history color-orange"
      icon-size="1rem"
      title="Historial de Citas de Cancelación"
      variant="custom-icon-view"
    />
    <custom-result-not-found v-if="info.cancellationHistory.length === 0" />
    <template v-else>
      <div
        v-for="(cancelation, idx) of computedCancelations"
        :key="`cancelation-${idx}`"
        class="row mx-0 mb-2 rounded bg-secondary-3 py-2 px-4"
      >
        <div class="col-12 col-md-2 mb-3 mb-md-0">
          <span class="data-title">N&uacute;mero de Cita:</span>
          {{ cancelation.clientAppointmentId }}
        </div>
        <div class="col-12 col-md-4 mb-3 mb-md-0">
          <span class="data-title">Fecha y Hora:</span>
          {{ cancelation.date | formatDate }}
          <em class="color-gray font-14">
            {{ cancelation.schedule }}
          </em>
        </div>
        <div class="col-12 col-md mb-3 mb-md-0">
          <span class="data-title">Raz&oacute;n de Cancelaci&oacute;n:</span>
          {{ cancelation.cancellationReason | paragraphCapitalize }}
        </div>
      </div>
      <div
        v-if="!showAllCancelations && needsPagination"
        class="d-flex justify-content-center"
      >
        <button class="btn custom-btn btn-outline-success" @click="showMore">
          <i class="far fa-chevron-double-down" />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import IconView from 'icon-view';
import CustomResultNotFound from 'custom-result-not-found';
import { getAppointmentDetailInfo } from '@/services/referralEvaluations/referralEvaluationApi';
import { phone, capitalize } from '@/utils/filters';
import moment from 'moment';

const GOOGLE_MAP_URL = 'https://www.google.com/maps/';
const CANCELATION_RECORDS_LIMIT = 3;
const paragraphCapitalize = (value) => {
  if (!value) return '';
  value = value.toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export default {
  name: 'AppointmentInfo',
  components: { IconView, CustomResultNotFound },
  filters: { phone, capitalize, paragraphCapitalize },
  props: {
    clientAppointmentId: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    info: { locationAddress: {}, cancellationHistory: [] },
    showAllCancelations: false,
  }),
  computed: {
    needsPagination() {
      return this.info.cancellationHistory?.length > CANCELATION_RECORDS_LIMIT;
    },
    computedCancelations() {
      return this.needsPagination && this.showAllCancelations
        ? this.info.cancellationHistory
        : this.info.cancellationHistory.slice(0, CANCELATION_RECORDS_LIMIT);
    },
    computedAddress() {
      return `${this.info.locationAddress.address1} ${
        this.info.municipality
      }, ${this.info.locationAddress.state || ''} ${
        this.info.locationAddress.zipCode
      }`;
    },
    formatedDate() {
      return `${this.formatHour(this.info.timeStart)} - ${this.formatHour(
        this.info.timeEnd
      )}`;
    },
  },
  async created() {
    await this.getInfo();
  },
  methods: {
    async getInfo() {
      const { data } = await getAppointmentDetailInfo(this.clientAppointmentId);
      this.info = { ...data };
    },
    formatHour(date) {
      return moment(date).format('hh:mm a');
    },
    showMore() {
      this.showAllCancelations = true;
    },
    openMaps() {
      window.open(GOOGLE_MAP_URL, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables/colors.scss';

.appointment-info ::v-deep {
  .data-title {
    display: block;
  }
  .custom-map-btn {
    border: 1px solid map-get($colors, 'gray-2');
    border-radius: 0.9rem;
    padding: 1rem 1.5rem;
    &:hover {
      color: map-get($colors, 'white') !important;
      .dot {
        border-color: map-get($colors, 'white');
      }
    }
    .dot {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      height: 27px;
      margin-right: 1rem;
      border-radius: 50%;
      border: 1px solid map-get($colors, 'black');
    }
  }
  .custom-btn.btn-outline-success {
    @extend .custom-map-btn;
    padding: 0.1rem;
    border-radius: 5px;
    width: 30%;
    margin-top: 0.5rem;
  }
  .custom-icon-view {
    margin-bottom: 0.5rem;
    .dot {
      box-shadow: none !important;
      margin-right: 0.5rem !important;
      .title-icon {
        margin: 0 !important;
      }
    }
    .font-16.color-gray-7 {
      font-size: 1rem !important;
      color: map-get($colors, 'dark-gray') !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-map-btn {
    width: 100% !important;
  }
}
</style>
