<template>
  <div>
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @evaluationType="getDisciplines"
      @search="onSearchHandler"
    />
    <custom-separator
      class="my-4"
      text="Resultado de Referidos de Evaluación"
    />
    <custom-result-not-found v-if="results.length === 0" />
    <result-row
      v-for="(row, idx) of results"
      :key="`result-${idx}`"
      class="mb-3"
      :data="row"
      @on-open-close="openCloseResultHandler($event, idx)"
    />
    <pagination
      v-if="pagination.totalItemCount > pagination.pageSize"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
  </div>
</template>

<script>
import Pagination from 'pagination';
import FilterManager from 'FilterManager';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from '@/views/students/referral-evaluations/components/ResultRow.vue';
import {
  getInitFilters,
  getAppointmentInfo,
  getDisciplineByEvaluation,
} from '@/services/referralEvaluations/referralEvaluationApi';

export default {
  name: 'StudentReferralEvaluations',
  props: { studentSieId: { type: [String, Number], default: null } },
  components: {
    FilterManager,
    Pagination,
    CustomSeparator,
    CustomResultNotFound,
    ResultRow,
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      pageSize: 10,
    },
    filters: {},
    filterOptions: {
      academicYears: [],
      evaluationTypes: [],
      disciplines: [],
    },
    results: [],
  }),
  computed: {
    filtersConfig() {
      return [
        {
          name: 'academicYear',
          component: 'CustomDropDown',
          options: this.filterOptions.academicYears,
          placeholder: 'Seleccione Año Académico',
          fieldName: 'description',
          fieldKey: 'id',
          clearable: true,
          class: 'col-12 col-md-4',
          initialValue: this.filters.academicYear,
        },
        {
          name: 'evaluationType',
          component: 'CustomDropDown',
          options: this.filterOptions.evaluationTypes,
          placeholder: 'Seleccione Tipo Evaluación',
          fieldName: 'description',
          fieldKey: 'evaluationTypeId',
          clearable: true,
          class: 'col-12 col-md-4',
          initialValue: this.filters.evaluationType,
        },
        {
          name: 'discipline',
          component: 'CustomDropDown',
          options: this.filterOptions.disciplines,
          placeholder: 'Seleccione Disciplina',
          fieldName: 'disciplineName',
          disabled: this.filterOptions.disciplines.length == 0,
          fieldKey: 'id',
          class: 'col-12 col-md-4',
          clearable: true,
          initialValue: this.filters.discipline,
        },
      ];
    },
  },
  async created() {
    await Promise.all([this.getFilters(), this.onSearchHandler()]);
  },
  methods: {
    async getFilters() {
      const { data } = await getInitFilters(this.studentSieId);
      this.filterOptions = {
        ...this.filterOptions,
        academicYears: data.academicYears,
        evaluationTypes: data.evaluationTypes,
      };
    },
    async getDisciplines(evaluation) {
      this.filters.discipline = null;
      this.filterOptions.disciplines = [];
      if (evaluation == null) return;
      const { data } = await getDisciplineByEvaluation(
        this.studentSieId,
        evaluation.evaluationTypeId
      );
      this.filterOptions.disciplines = data;
    },
    openCloseResultHandler(value, idx) {
      this.results = this.results.map((r, i) => ({
        ...r,
        isOpen: i === idx ? !value : false,
      }));
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.onSearchHandler();
    },
    async onSearchHandler() {
      const model = { ...this.filters };
      const params = {
        studentSieId: Number(this.studentSieId),
        academicYearsId: model.academicYear?.id,
        evaluationTypeId: model.evaluationType?.evaluationTypeId,
        disciplineId: model.discipline?.id,
      };
      const { data } = await getAppointmentInfo(
        params,
        this.pagination.currentPage,
        this.pagination.pageSize
      );
      this.results = [...data.data];
      this.pagination = {
        ...this.pagination,
        totalItemCount: data.totalItemCount,
        pageCount: data.pageCount,
        currentPage: data.currentPage,
        count: data.count,
      };
    },
  },
};
</script>
