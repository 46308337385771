<template>
  <div v-if="studentSieId">
    <page-header
      has-return
      :page="{
        icon: 'fas fa-user-edit',
        title: 'Referidos de Evaluación del Estudiante',
        routes: ['Inicio', 'Referidos de Evaluación del Estudiante'],
      }"
    />

    <student-referral-evaluations :student-sie-id="Number(studentSieId)" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import StudentReferralEvaluations from './components/StudentReferralEvaluations';

export default {
  name: 'ReferralEvaluations',
  components: { PageHeader, StudentReferralEvaluations },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
